<template>
  <v-sheet class="transparent page-sheet">
    <v-row justify="space-around" class="mt-10">
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-card class="yellow lighten-5">
              <v-card-title>
                <i18n path="home.lictitle" />
              </v-card-title>
              <v-card-text>
                <i18n path="home.lictext" />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" to="/buy" icon>
                  <i18n path="home.licbtn" />
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="yellow lighten-5">
              <v-card-title>
                <i18n path="home.prgtitle" />
              </v-card-title>
              <v-card-text>
                <i18n path="home.prgtext" />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" :href="$api.api+'/centrs/index.html'" target="_blank">
                  <i18n path="home.prgbtn" />
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="yellow lighten-5">
              <v-card-title>
                <i18n path="home.msgtitle" />
              </v-card-title>
              <v-card-text>
                <v-form ref="feedback">
                  <v-text-field v-model="subject" :label="$t('home.msgsubj')" :rules="required"></v-text-field>
                  <v-textarea v-model="text" :label="$t('home.msgtext')" :rules="required"></v-textarea>
                  <v-text-field v-model="email" :label="$t('home.msgemail')" name="email"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="sendFeedback">
                  <i18n path="home.msgbtn" />
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      email: null,
      subject: null,
      text: null,
      required: [(v) => !!v || this.$t("error.required")],
    };
  },
  methods: {
    sendFeedback() {
      if (!this.$refs.feedback.validate()) return;
      this.$api
        .apiPostRequest("dmz/feedback", {
          subject: this.subject,
          text: this.text,
          email: this.email,
        })
        .then((r) => {
          if (r == "ok") {
            this.subject = null;
            this.text = null;
          }
        });
    },
  },
};
</script>

<style>
</style>